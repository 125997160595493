import React, { useState, useEffect } from 'react';
import { CorpUpload } from '@digi-tim-19/components';
import { Button } from 'antd';

type TDocsEditorFilesProps = {
  initialFiles?: { _id: string; extension: string }[];
  onChange: (fileIds: string[]) => any;
};

export const DocsEditorFiles = (props: TDocsEditorFilesProps) => {
  const anxLimit = 100;
  const imgLimit = 200;
  const [imageIds, setImageIds] = useState<string[]>([]);
  const [anexIds, setAnexIds] = useState<string[]>([]);

  const acceptedDocs = '.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx';
  const acceptedImgs = '.png,.jpg,.jpeg,.PNG,.JPG,.JPEG';

  useEffect(() => {
    if (props.initialFiles) {
      const images = props.initialFiles.filter((el) =>
        acceptedImgs.includes(el.extension)
      );
      const anexs = props.initialFiles.filter((el) =>
        acceptedDocs.includes(el.extension)
      );
      setImageIds(images.map((el) => el._id));
      setAnexIds(anexs.map((el) => el._id));
    }
  }, []);

  useEffect(() => {
    props.onChange([...anexIds, ...imageIds]);
  }, [anexIds, imageIds]);

  return (
    <>
      <p style={{ fontSize: '14px' }}>
        Realize a importação de todos os arquivos de texto e imagens.
      </p>
      <div style={{ display: 'flex', width: '100%' }}>
        <CustomUpload
          limit={anxLimit}
          initialFiles={anexIds}
          label="Upload de anexos"
          text={`Arquivos carregados: ${anexIds.length} de ${anxLimit} (limite).`}
          onChange={setAnexIds}
          accept={acceptedDocs}
        />
        <CustomUpload
          limit={imgLimit}
          initialFiles={imageIds}
          label="Upload de imagens"
          text={`Imagens carregadas: ${imageIds.length} de ${imgLimit} (limite).`}
          onChange={setImageIds}
          accept={acceptedImgs}
        />
      </div>
    </>
  );
};

interface ICustomUploadProps {
  text: string;
  label: string;
  limit: number;
  accept?: string;
  initialFiles?: string[];
  onChange: (files: string[]) => void;
}
const CustomUpload = (props: ICustomUploadProps) => {
  const [showUpload, setShowUpload] = useState(true);
  const toogleShowUpload = () => setShowUpload((e) => !e);
  const excedLimit = props.initialFiles
    ? props.initialFiles?.length >= props.limit
    : false;

  return (
    <div style={{ display: 'flex', width: '50%', position: 'relative' }}>
      <CorpUpload
        initialValues={props.initialFiles ?? []}
        onChange={(files) => props.onChange(files)}
        showUploadList={showUpload}
        accept={props.accept}
        disabled={excedLimit}
      >
        {props.label}
      </CorpUpload>
      <div
        style={{
          display: 'flex',
          gap: '8px',
          position: 'absolute',
          top: '4px',
          right: '4px'
        }}
      >
        <p style={{ fontSize: '14px', color: excedLimit ? 'red' : undefined }}>
          {props.text}
        </p>
        {showUpload ? (
          <Button icon="up" size="small" onClick={toogleShowUpload} />
        ) : (
          <Button icon="down" size="small" onClick={toogleShowUpload} />
        )}
      </div>
    </div>
  );
};
